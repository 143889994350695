<template>
  <loading v-if="loading"></loading>
  <v-container v-else class="pt-0 px-2">
    <v-img
      :src="require('@/assets/byCategory_bg.png')"
      contain
      style="position: absolute; top: 0; right: 0"
    ></v-img>
    <back-toolbar :title="$t(`categories.${category.title}`)"></back-toolbar>
    <div class="d-flex flex-column justify-space-between align-center">
      <v-img
        height="178px"
        width="216px"
        contain
        :src="category.image"
        :lazy-src="category.image"
      />
    </div>
    <v-row class="px-2">
      <!-- not removed radius slider -->
      <v-col cols="12" class="px-5 py-0">{{ $t("dashboard.radius") }} </v-col>
      <v-col cols="10" class="pt-0">
        <v-slider
          class="category-slider"
          dense
          min="1"
          max="100"
          @touchend="updateData"
          @mouseup="updateData"
          :thumb-size="20"
          thumb-label="always"
          v-model="radius"
        >
          <template v-slot:thumb-label="{ value }"> {{ value }} km </template>
        </v-slider>
      </v-col>
      <!-- -->
      <!-- not added spacer -->
      <!-- <v-col cols="10"></v-col> -->
      <v-col cols="2" class="pt-0"
        ><v-img
          :src="
            !darkMode
              ? require('@/assets/img/filter_horizontal.svg')
              : require('@/assets/img/filter_horizontal_white.svg')
          "
          width="24"
          :disabled="!subCategories.length"
          height="24"
          @click="openDialog()"
      /></v-col>
    </v-row>
    <item-list
      v-if="items && items.length > 0"
      :items="items"
      :showSegmentedControl="false"
    ></item-list>
    <error-image
      v-else-if="byCategoryStatus !== 'fetching'"
      height="calc(calc(100vh - 465px) - env(safe-area-inset-top))"
      :errorDialog="false"
      :text="$t('dashboard.noRecommendation')"
      imageName="norecommendation.svg"
    ></error-image>
    <!-- <loading v-if="byCategoryMoreStatus === 'fetching'"></loading> -->
    <v-bottom-sheet v-model="filterDialog">
      <v-sheet
        class="text-center"
        height="65vh"
        style="border-radius: 30px 30px 0px 0px"
      >
        <v-toolbar
          style="border-radius: 30px 30px 0px 0px; background: transparent"
          flat
        >
          <v-btn icon @click="filterDialog = false"
            ><v-icon> mdi-close </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <div class="CAPS-HEADING-DARK-CAPS2-12-CENTER text-uppercase">
            {{ $t("search.filters") }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            text
            small
            @click="$store.commit('recommendations/CLEAR_SUB_FILTER')"
            class="CAPS-HEADING-DARK-CAPS2-12-CENTER text-uppercase"
            >{{ $t("search.clear") }}</v-btn
          >
        </v-toolbar>
        <div
          class="Heading-Dark-H4-18-Left-Align px-5 py-1"
          :style="!darkMode ? 'background-color: #f5f5f5;' : ''"
        >
          {{ $t("dashboard.categories") }}
        </div>
        <v-container class="px-5 filter-items pb-8">
          <v-row
            class="mb-2"
            v-for="(i, index) in Math.ceil(subCategories.length / 2)"
            :key="index"
          >
            <v-col
              class="py-1"
              cols="6"
              v-for="(item, index) in subCategories.slice((i - 1) * 2, i * 2)"
              :key="index"
            >
              <v-checkbox
                v-if="item && item.title"
                v-model="item.selected"
                :label="item.title"
                class="py-0 mt-0 Text-Dark-Text-2---16-Left-Align"
                :class="darkMode ? 'white--text' : ''"
                :ripple="false"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <div
          style="position: sticky; bottom: 12px"
          class="pt-3"
          :style="
            darkMode ? 'background-color: #1e1e1e' : 'background-color: #fff'
          "
        >
          <v-btn
            min-width="150"
            height="48"
            color="primary"
            class="Heading-White-H4-18-Center gradient"
            depressed
            @click="filterDialog = false"
          >
            {{ $t("search.startSearch") }}
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import ItemList from "@/components/recommendations/ItemList";
import BackToolbar from "@/components/app/common/BackToolbar";
import ErrorImage from "@/components/app/common/ErrorImage";
import Loading from "@/components/app/common/Loading";
import find from "lodash/find";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  async beforeMount() {
    if (this.byCategoryStatus === "") {
      await this.getByCategory({ category: this.category.id });
    }
    // this.$nextTick(function () {
    //   window.addEventListener("scroll", this.onScroll);
    // });
    // if (this.pageYOffset) {
    //   window.scrollTo({
    //     top: this.pageYOffset,
    //   });
    // }
  },
  mounted() {
    if (this.isMiKY && this.$router.from.name !== "detail") {
      this.logCategoryView({
        categoryId: this.category.id,
        categoryName: this.category.title,
      });
    }
  },
  // beforeDestroy() {
  //   this.radius = 20;
  //   window.removeEventListener("scroll", this.onScroll);
  //   if (this.$router.past[this.$router.past.length - 1].name !== "detail") {
  //     this.setPageYOffset(undefined);
  //   }
  // },
  data: () => ({
    filterDialog: false,
    // getMore: false,
  }),
  components: {
    Loading,
    BackToolbar,
    ItemList,
    ErrorImage,
  },
  computed: {
    ...mapGetters("staticRecommendations", ["taxi"]),
    ...mapState({
      isMiKY: (state) => state.isMiKY,
      categories: ({ categories }) => categories.categories,
      moods: ({ moods }) => moods.moods,
      pageYOffset: ({ session }) => session.pageYOffset,
      byCategory: ({ recommendations }) => recommendations.byCategoryV4,
      byCategoryStatus: ({ recommendations }) =>
        recommendations.byCategoryV4Status,
      // byCategoryMoreStatus: ({ recommendations }) =>
      //   recommendations.byCategoryMoreStatus,
      // byMood: ({ recommendations }) => recommendations.byMood,
      // byMoodStatus: ({ recommendations }) => recommendations.byMoodStatus,
      // byMoodMoreStatus: ({ recommendations }) =>
      //   recommendations.byMoodMoreStatus,
      subCategories: ({ recommendations }) => recommendations.subCategoriesV4,
      darkMode: ({ settings }) => settings.dark,
    }),
    radius: {
      get() {
        return this.$store.state.recommendations.radius;
      },
      set(val) {
        this.setRadius(val);
      },
    },
    category() {
      // if (this.$route.params.type === "category") {
      return find(this.categories, (item) => {
        return item.id === this.$route.params.id;
      });
      // } else {
      //   return find(this.moods, (item) => {
      //     return item._fl_meta_.fl_id === this.$route.params.id;
      //   });
      // }
    },
    items() {
      // RO items first, then rest. Both parts sorted by distance.
      const pocoSort = function (i) {
        let reserveout = orderBy(
          filter(i, (o) => {
            return o.source === "reserve-out";
          }),
          ["distance"],
          ["asc"]
        );

        let rest = orderBy(
          filter(i, (o) => {
            return o.source !== "reserve-out";
          }),
          ["distance"],
          ["asc"]
        );

        return [...reserveout, ...rest];
      };
      const subcategoryFilter = function (items, subcategories) {
        return filter(items, (o) => {
          return (
            o.subCategory &&
            o.subCategory.find((x) => subcategories.some((y) => x === y.title))
          );
        });
      };

      const ssc = filter(this.subCategories, {
        selected: true,
      });
      // let items = [];
      // if (this.$route.params.type === "category") {
      let items = this.byCategory;
      // } else {
      //   items = this.byMood;
      // }
      // Don't filter while the dialog is open
      if (!this.filterDialog && ssc.length) {
        items = subcategoryFilter(items);
      }

      const seoItems = pocoSort(items.filter((i) => i.seoRelevant));
      const otherItems = pocoSort(items.filter((i) => !i.seoRelevant));

      if (this.category.title === "Mobility") {
        seoItems.unshift(this.taxi);
      }

      return [...seoItems, ...otherItems];
    },
    // hasAllItems() {
    //   if (this.$route.params.type === "category") {
    //     return this.byCategoryMoreStatus === "failure" && this.getMore;
    //   } else {
    //     return this.byMoodMoreStatus === "failure" && this.getMore;
    //   }
    // },
    loading() {
      return (
        this.byCategoryStatus === "fetching" || this.byCategoryStatus === ""
      );
    },
  },
  methods: {
    ...mapActions("recommendations", {
      getMoreByCategory: "getMoreByCategory",
      getByCategory: "getByCategoryV4",
      // getByMood: "getByMood",
      logCategoryView: "logCategoryView",
    }),
    ...mapMutations("recommendations", {
      setRadius: "SET_RADIUS",
    }),
    ...mapMutations("session", {
      setPageYOffset: "SET_PAGEYOFFSET",
    }),
    // onScroll() {
    //   this.setPageYOffset(window.pageYOffset);
    //   // Load more if close to the bottom and not loading
    //   if (this.$route.params.type === "category") {
    //     if (
    //       this.items.length > 0 &&
    //       !this.hasAllItems &&
    //       this.byCategoryMoreStatus !== "fetching" &&
    //       window.pageYOffset + window.innerHeight >=
    //         document.body.scrollHeight - 20
    //     ) {
    //       this.getMoreByCategory({
    //         category: this.$route.params.id,
    //       });
    //       this.getMore = true;
    //     }
    //   } else {
    //     if (
    //       this.items.length > 0 &&
    //       !this.hasAllItems &&
    //       this.$store.state.recommendations.byMoodMoreStatus !== "fetching" &&
    //       window.pageYOffset + window.innerHeight >=
    //         document.body.scrollHeight - 20
    //     ) {
    //       this.$store.dispatch("recommendations/getMoreByMood", {
    //         mood: this.$route.params.id,
    //       });
    //       this.getMore = true;
    //     }
    //   }
    // },
    updateData() {
      // if (this.$route.params.type === "category") {
      this.getByCategory({
        category: this.$route.params.id,
      });
      // } else {
      //   this.getByMood({
      //     mood: this.$route.params.id,
      //   });
      // }
      // this.getMore = false;
    },
    openDialog() {
      // if (this.$route.params.type === "category") {
      this.filterDialog = true;
      // }
    },
  },
  // watch: {
  //   "$store.state.recommendations": {
  //     handler(val) {
  //       if (
  //         val?.byCategoryStatus !== "failure" &&
  //         val?.byCategoryStatus !== "fetching" &&
  //         val?.byCategoryMoreStatus !== "failure" &&
  //         val?.byCategoryMoreStatus !== "fetching" &&
  //         !this.hasAllItems &&
  //         this.items.length < 10
  //       ) {
  //         this.loadMore();
  //       }
  //     },
  //     deep: true,
  //   },
  // },
};
</script>
<style>
.filter-items .v-input--checkbox label {
  color: #202224 !important;
  font-size: 16px;
}
.filter-items .white--text.v-input--checkbox label {
  color: #fff !important;
  font-size: 16px;
}
.filter-items .v-input--checkbox i {
  color: #9da6aa !important;
  font-size: 30px !important;
  border: 2px solid #9da6aa;
  border-radius: 5px;
  color: transparent !important;
}
.filter-items .v-input--selection-controls .mdi-checkbox-marked::before {
  background-image: radial-gradient(
    circle at 0 8%,
    #01a2e9,
    #56c0df 79%,
    #86d0da 98%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.filter-items {
  max-height: 50vh !important;
  overflow: auto !important;
}
@media (max-height: 650px) {
  .filter-items {
    max-height: 40vh !important;
    overflow: auto !important;
  }
}
</style>
